

const CustomSuspense = () => {
    return(
        <div style = {{display: 'flex',flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <img width = '30px' height = '30px'src="https://i.gifer.com/ZKZg.gif"/>
            <p>로딩중...</p>
        </div>
    )
}

export default CustomSuspense